import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { PersonalConstants } from 'app/common/personalConstants';
import { LoggerService } from './logger.service';

@Injectable()
export class ValidatorService {
    constructor (
        private logger: LoggerService
    ) { }

    checkFutureDate(): ValidatorFn {
        return (c: UntypedFormControl): any => {
            const chosen = new Date(c.value);
            const now = new Date();
            if (chosen >= now || c.value === null) {
                return { 'checkDate': true };
            } else {
                return null;
            }
        };
    }
    validateTimeInput(defaultValue): ValidatorFn {
        return (c: UntypedFormControl): any => {
            if (c.value === defaultValue) {
                return { 'invalid': true };
            } else {
                return null;
            }
        };
    }


    checkInputDate(): ValidatorFn {
        return (c: UntypedFormControl): any => {
            if (!c.value) {
                return { 'patternError': true };
            }
            const value = new Date(c.value);
            const day = value.getDate() < 10 ? `0${value.getDate()}` : value.getDate();
            const month = (value.getMonth() + 1) < 10 ? `0${value.getMonth() + 1}` : value.getMonth() + 1;
            const chosen = [month, day, value.getFullYear()].join('/');
            if (PersonalConstants.regexValidators.isValidDate.test(chosen)) {
                return null;
            } else {
                return { 'patternError': true };
            }
        };
    }

    emailValidation(regex: any): ValidatorFn {
        return (emailValue: UntypedFormControl): any => {
            const email = emailValue.value;
            const matchPattern = RegExp(regex);
            if (!matchPattern.test(email)) {
                return { 'emailError': true };
            } else {
                return null;
            }
        };
    }

    emailValidationWithNullCheck(regex: any): ValidatorFn {
        return (emailValue: UntypedFormControl): any => {
            const email = emailValue.value;
            const matchPattern = RegExp(regex);
            if (email && !matchPattern.test(email)) {
                return { 'emailError': true };
            } else {
                return null;
            }
        };
    }

    textBoxValidation(regex: any): ValidatorFn {
        return (textBoxValue: UntypedFormControl): any => {
            const text = textBoxValue.value;
            const matchPattern = RegExp(regex);
            if (!text) {
                return { 'required': true };
            } else if (!matchPattern.test(text)) {
                return { 'patternError': true };
            } else {
                return null;
            }
        };
    }

    checkMaskedPhoneNumbers(home: string, cell: string, business: string, regex: any): { flag: boolean, phoneTypes: string []} {
        let flag = false;
        const phoneTypes: string[] = [];
        const matchPattern = RegExp(regex);
        if (home && matchPattern.test(home)) {
            flag = true;
            phoneTypes.push('home');
        }
        if (cell && matchPattern.test(cell)) {
            flag = true;
            phoneTypes.push('cell');
        }
        if (business && matchPattern.test(business)) {
            flag = true;
            phoneTypes.push('business');
        }

        return { flag, phoneTypes };
    }

    nameFieldValidation(regex: any): ValidatorFn {
        return (textBoxValue: UntypedFormControl): any => {
            const text = textBoxValue.value;
            const matchPattern = RegExp(regex);
            if (!matchPattern.test(text)) {
                return { 'nameFieldError': true };
            } else {
                return null;
            }
        };
    }

    routingNumberValidation(regex: any): ValidatorFn {
        return (routingNumberValue: UntypedFormControl): any => {
            const routingNumber = routingNumberValue.value;
            const matchPattern = RegExp(regex);
            if (!matchPattern.test(routingNumber)) {
                return { 'routingNumberError': true };
            } else {
                return null;
            }
        };
    }

    fieldMessageValidation(regex: any): ValidatorFn {
        return (textBoxValue: UntypedFormControl): any => {
            const text = textBoxValue.value;
            const matchPattern = RegExp(regex);
            if (!text) {
                return { 'required': true };
            } else if (!matchPattern.test(text)) {
                return { 'fieldMessage': true };
            } else {
                return null;
            }
        };
    }

    textAreaValidation(regex: any): ValidatorFn {
        return (textBoxValue: UntypedFormControl): any => {
            const text = textBoxValue.value;
            const matchPattern = RegExp(regex);
            if (!text) {
                return { 'required': true };
            } else if (!matchPattern.test(text)) {
                this.logger.info(`Claim-Modernization: Users entered special characters in Incident Description.`);
                return { 'areaMessage': true };
            } else {
                return null;
            }
        };
    }


    confirmFields(field): ValidatorFn {
        return (confirmField: UntypedFormControl): any => {
            if (field !== confirmField.value) {
                return { 'confirmFieldError': true };
            } else {
                return null;
            }
        };
    }

    phoneNumberValidationAcceptBlank(regex: any, validReq = true): ValidatorFn {
        return (phoneValue: UntypedFormControl): any => {
            const phone = phoneValue.value;
            const matchPattern = RegExp(regex);
            if ((phoneValue.value === '' || phone === undefined || phone == null) && !validReq) {
                validReq = false;
                return null;
            }
            if (!matchPattern.test(phone)) {
                return { 'phoneNumberError': true };
            } else {
                return null;
            }
        };
    }

    phoneNumberValidation(regex: any, validReq = true): ValidatorFn {
        return (phoneValue: UntypedFormControl): any => {
            const phone = phoneValue.value;
            const matchPattern = RegExp(regex);
            if (phoneValue.value === '' && !validReq) {
                validReq = false;
                return null;
            }
            if (!matchPattern.test(phone)) {
                return { 'phoneNumberError': true };
            } else {
                return null;
            }
        };
    }

    checkBoxFalsy(): ValidatorFn {
        return (checkBoxValue: UntypedFormControl): any => {
            if (checkBoxValue.value === false) {
                return { 'checkBoxError': true };
            } else {
                return null;
            }
        };
    }

    validateAllFormFields(formGroup: UntypedFormGroup): UntypedFormGroup {
        Object.keys(formGroup.controls).forEach((field) => {
            const control = formGroup.get(field);
            if (control instanceof UntypedFormControl) {
                control.markAsTouched();
            } else if (control instanceof UntypedFormGroup) {
                this.validateAllFormFields(control);
            } else if (control instanceof UntypedFormArray) {
                control.markAsTouched();
            }
        });
        return formGroup;
    }

    claimAccessCode(): ValidatorFn {
        return (claimsAccessCode: UntypedFormControl): any => {
            const accesscode = claimsAccessCode.value;
            if (accesscode) {
                if (accesscode.length >= 1 && (!accesscode.match(/^[a-zA-Z0-9]+$/) || accesscode.length !== 10)) {
                    return { 'claimAccessCodeError': true };
                } return null;
            }
        };
    }
    validTime(regex: any): ValidatorFn {
        return (timeValue: UntypedFormControl): any => {
            const time = timeValue.value;
            const matchPattern = RegExp(regex);
            if (!matchPattern.test(time)) {
                return { 'timeValueError': true };
            } else {
                return null;
            }
        };
    }

    sixDigitCode(): ValidatorFn {
        return (sixDigitCode: UntypedFormControl): any => {
            const code = sixDigitCode.value;
            if (code) {
                if (code.length >= 1 && code.length < 6) {
                    return { 'sixDigitCodeError': true };
                } return null;
            }
        };
    }
    checkDropdownfield(): ValidatorFn {
        return (relationsValue: UntypedFormControl): any => {
            const dropdowmValue = relationsValue.value;
            if (dropdowmValue === '' || dropdowmValue === null) {
                return { 'selectOptionPattern': true };
            }
        };
    }

    acknowledgeCheckBoxValidation(): ValidatorFn {
        return (acknowledgeValue: UntypedFormControl): any => {
            const checkBoxValue = acknowledgeValue.value;
            if (checkBoxValue === false) {
                return { 'acknowledgmentError': true };
            } return null;
        };
    }

    insuredInjuredValidation(): ValidatorFn {
        return (insuredInjured: UntypedFormControl): any => {
            const radioButton = insuredInjured.value;
            if (radioButton === null) {
                return { 'insuredInjuredError': true };
            } return null;
        };
    }

    phoneValidation(): ValidatorFn {
        return (phoneNumber: UntypedFormControl): any => {
            const value = phoneNumber.value;
            const formattedvalue = this.formatPhoneNumber(value);
            if (value) {
                if (value !== formattedvalue) {
                    return { 'phoneFormatError': true };
                } return null;
            }
        };
    }

    formatPhoneNumber(phone): string {
        const cleaned = (`${phone}`).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `${match[1]}-${match[2]}-${match[3]}`;
        }
        return phone;
    }

    validateTillCurrentYear(): ValidatorFn {
        return (control: UntypedFormControl): any => {
            const year = parseInt(control.value, 10);
            if (year < 1900 || year > (new Date()).getFullYear() + 2) {
                return ({ 'checkYear': true });
            } else {
                return null;
            }
        };
    }

    checkPolicyFormat(): ValidatorFn {
        return (control: UntypedFormControl): any => {
            const policyNumber = control.value;
            const matchPattern = RegExp(PersonalConstants.regexValidators.isValidPolicyNumber);
            if (!matchPattern.test(policyNumber)) {
                return { 'fieldMessage': true };
            } else {
                return null;
            }
        };
    }

    checkPolicyNumber(): ValidatorFn {
        return (control: UntypedFormControl): any => {
            const policyNumber = control.value;
            const matchCommercialPattern = RegExp(PersonalConstants.regexValidators.isCommercialPolicy);
            const matchPattern = RegExp(PersonalConstants.regexValidators.isNotAnAutoPolicy);
            if (!matchPattern.test(policyNumber)) {
                if (matchCommercialPattern.test(policyNumber)) {
                    return { 'commercialPolicy': true };
                } else {
                    return { 'invalidPolicy': true };
                }
            } else {
                return null;
            }
        };
    }
}

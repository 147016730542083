import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SessionService } from 'app/shared/services/session.service';

@Component({
    selector: 'is-header-progress-bar',
    templateUrl: './header-progress-bar.component.html',
    styleUrls: ['./header-progress-bar.component.scss']
})
export class HeaderProgressBarComponent implements OnInit {
    menu = false;
    showBasicInfo = false;
    showIncidentDetail = false;
    progressBarPercentage: any;
    showServices = false;
    showSummary = false;
    currentTab: any;
    currentTabSection: any;
    URL: any;
    tabFlow = 'previous';
    tabsList = [
        { unselectedPast: false, Current: false, unselectedFuture: false, title: '1. Basic information', completed: false },
        { unselectedPast: false, Current: false, unselectedFuture: false, title: '2. Incident details', completed: false },
        { unselectedPast: false, Current: false, unselectedFuture: false, title: '3. Services', completed: false },
        { unselectedPast: false, Current: false, unselectedFuture: false, title: '4. Claim summary', completed: false }
    ];
    basicInfoList = [
        { current: false, past: false, future: false, title: 'Claim type' },
        { current: false, past: false, future: false, title: 'Contact' },
        { current: false, past: false, future: false, title: 'Incident overview' }
    ];
    accidentDetailsList = [
        { current: false, past: false, future: false, title: 'Vehicle details' },
        { current: false, past: false, future: false, title: 'Occupant details' },
        { current: false, past: false, future: false, title: 'Damages' },
        { current: false, past: false, future: false, title: 'Location' },
        { current: false, past: false, future: false, title: 'Other' }
    ];
    servicesList = [
        { current: false, past: false, future: false, title: 'Inspection options' },
        { current: false, past: false, future: false, title: 'Photos' },
        { current: false, past: false, future: false, title: 'Repair shops' },
        { current: false, past: false, future: false, title: 'Tow & rentals' }
    ];
    claimsSummaryList = [
        { current: false, past: false, future: false, title: 'Other documents' },
        { current: false, past: false, future: false, title: 'Preferences' },
        { current: false, past: false, future: false, title: 'Complete' }
    ];
    screenURLs = [
        { urlEnding: 'claim-process-intro', tab: '1. Basic information', subTab: 'Claim type' },
        { urlEnding: 'typeofclaimdescription', tab: '1. Basic information', subTab: 'Claim type' },
        { urlEnding: 'identification', tab: '1. Basic information', subTab: 'Contact' },
        { urlEnding: 'no-policy-found', tab: '1. Basic information', subTab: 'Claim type' },
        { urlEnding: 'no-policy-found-third-party', tab: '1. Basic information', subTab: 'Claim type' },
        { urlEnding: 'multiple-active-policies', tab: '1. Basic information', subTab: 'Claim type' },
        { urlEnding: 'contact-details-unauth', tab: '1. Basic information', subTab: 'Contact' },
        { urlEnding: 'contacts-for-claim', tab: '1. Basic information', subTab: 'Contact' },
        { urlEnding: 'notifications', tab: '1. Basic information', subTab: 'Contact' },
        { urlEnding: 'when-did-accident-occur', tab: '1. Basic information', subTab: 'Incident overview' },
        { urlEnding: 'duplicate-claim', tab: '1. Basic information', subTab: 'Incident overview' },
        { urlEnding: 'accident-location', tab: '1. Basic information', subTab: 'Incident overview' },
        { urlEnding: 'accident-type', tab: '1. Basic information', subTab: 'Incident overview' },
        { urlEnding: 'accident-cause', tab: '1. Basic information', subTab: 'Incident overview' },
        { urlEnding: 'incident-description', tab: '1. Basic information', subTab: 'Incident overview' },
        { urlEnding: 'accident-overview', tab: '1. Basic information', subTab: 'Incident overview' },
        { urlEnding: 'type-of-animal', tab: '2. Incident details', subTab: 'Vehicle details' },
        { urlEnding: 'vehicle-details', tab: '2. Incident details', subTab: 'Vehicle details' },
        { urlEnding: 'occupant-details', tab: '2. Incident details', subTab: 'Occupant details' },
        { urlEnding: 'ao-accident-with-object', tab: '2. Incident details', subTab: 'Occupant details' },
        { urlEnding: 'other-accident-causes', tab: '2. Incident details', subTab: 'Other' },
        { urlEnding: 'av3-other-vehicles', tab: '2. Incident details', subTab: 'Occupant details' },
        { urlEnding: 'what-was-stolen', tab: '2. Incident details', subTab: 'Damages' },
        { urlEnding: 'other-causes', tab: '2. Incident details', subTab: 'Damages' },
        { urlEnding: 'point-of-impact-v2', tab: '2. Incident details', subTab: 'Damages' },
        { urlEnding: 'areas-of-damage', tab: '2. Incident details', subTab: 'Damages' },
        { urlEnding: 'additional-details', tab: '2. Incident details', subTab: 'Damages' },
        { urlEnding: 'vehicle-location', tab: '2. Incident details', subTab: 'Location' },
        { urlEnding: 'other-party-accident', tab: '2. Incident details', subTab: 'Occupant details' },
        { urlEnding: 'other-vehicle-damage', tab: '2. Incident details', subTab: 'Other' },
        { urlEnding: 'other-vehicle-information', tab: '2. Incident details', subTab: 'Other' },
        { urlEnding: 'create-note', tab: '1. Basic information', subTab: 'Other' },
        { urlEnding: 'other-damage', tab: '2. Incident details', subTab: 'Other' },
        { urlEnding: 'other-vehicle-additional-details', tab: '2. Incident details', subTab: 'Other' },
        { urlEnding: 'predicted-total-loss', tab: '3. Services', subTab: 'Inspection options' },
        { urlEnding: 'inspection-options', tab: '3. Services', subTab: 'Inspection options' },
        { urlEnding: 'preferred-repair-shop', tab: '3. Services', subTab: 'Inspection options' },
        { urlEnding: 'photo-process-options', tab: '3. Services', subTab: 'Photos' },
        { urlEnding: 'gpp-overview', tab: '3. Services', subTab: 'Photos' },
        { urlEnding: 'gpp-without-support', tab: '3. Services', subTab: 'Photos' },
        { urlEnding: 'photo-upload-overview', tab: '3. Services', subTab: 'Photos' },
        { urlEnding: 'photo-upload', tab: '3. Services', subTab: 'Photos' },
        { urlEnding: 'repair-shops-oys', tab: '3. Services', subTab: 'Repair shops' },
        { urlEnding: 'drive-in-inspection', tab: '3. Services', subTab: 'Repair shops' },
        { urlEnding: 'towing-options', tab: '3. Services', subTab: 'Tow & rentals' },
        { urlEnding: 'rental-options', tab: '3. Services', subTab: 'Tow & rentals' },
        { urlEnding: 'document-dashboard', tab: '4. Claim summary', subTab: 'Other documents' },
        { urlEnding: 'document-upload', tab: '4. Claim summary', subTab: 'Other documents' },
        { urlEnding: 'contact-preferences', tab: '4. Claim summary', subTab: 'Preferences' },
        { urlEnding: 'customer-feedback', tab: '4. Claim summary', subTab: 'Complete' },
        { urlEnding: 'claim-summary', tab: '4. Claim summary', subTab: 'Complete' }


    ];
    list: any;
    userFlow: any;
    browserRefresh = false;
    userType: any;
    constructor (private sessionService: SessionService,
        router: Router,
    ) {
        router.events.subscribe((events) => {
            if (events instanceof NavigationEnd) {
                this.ngOnInit();
            }
        });
    }

    ngOnInit(): void {
        this.URL = document.URL;
        this.userType = this.sessionService.getUserType();
        if ((this.userType === 'registered' || this.userType === 'unregistered-member-support' || this.userType === 'unregistered-member') &&
            this.screenURLs[10].urlEnding === 'when-did-accident-occur') {
            const dateProgressBarEntry = {
                urlEnding: 'when-did-accident-occur', tab: '1. Basic information', subTab: 'Incident date'
            };
            const duplicateProgressBarEntry = {
                urlEnding: 'duplicate-claim', tab: '1. Basic information', subTab: 'Incident date'
            };
            const authBasicInfoTab = {
                current: false, past: false, future: false, title: 'Incident date'
            };
            this.screenURLs.splice(10, 2);
            this.screenURLs.splice(7, 0, dateProgressBarEntry);
            this.screenURLs.splice(8, 0, duplicateProgressBarEntry);
            this.basicInfoList.splice(1, 0, authBasicInfoTab);
        }

        if (this.URL.includes('create-note')) {
            const createNoteEntry = { current: false, past: false, future: false, title: 'Other' };
            this.basicInfoList.splice(3, 1, createNoteEntry);
        }

        this.setTabAndSection();
        this.changeSelected(this.currentTab);
    }

    showDropDown(): void {
        this.menu = !this.menu;
    }

    showSubMenus(tab: any): void {
        if (tab === '1. Basic information') {
            this.showBasicInfo = !this.showBasicInfo;
        } else if (tab === '2. Incident details') {
            this.showIncidentDetail = !this.showIncidentDetail;
        } else if (tab === '3. Services') {
            this.showServices = !this.showServices;
        } else if (tab === '4. Claim summary') {
            this.showSummary = !this.showSummary;
        }
    }

    setTabAndSection(): void {
        this.currentTab = null;
        for (const screen in this.screenURLs) {
            if (this.URL.includes(this.screenURLs[screen].urlEnding)) {
                this.currentTab = this.screenURLs[screen].tab;
                this.currentTabSection = this.screenURLs[screen].subTab;
                this.progressBarPercentage = Math.round((Number(screen) / (this.screenURLs.length - 1)) * 100);
            }
        }
    }

    changeTab(selected: any): void {
        let future = false;
        for (const tab in this.tabsList) {
            if (selected !== this.tabsList[tab].title && !future) {
                this.tabsList[tab].unselectedPast = true;
                this.tabsList[tab].Current = false;
                this.tabsList[tab].unselectedFuture = false;
            } else if (selected === this.tabsList[tab].title) {
                this.tabsList[tab].Current = true;
                this.tabsList[tab].unselectedPast = false;
                this.tabsList[tab].unselectedFuture = false;
                future = true;
                this.changeSubTabs(this.tabsList[tab].title);
            } else {
                this.tabsList[tab].Current = false;
                this.tabsList[tab].unselectedPast = false;
                this.tabsList[tab].unselectedFuture = true;
            }
        }
    }

    changeSubTabs(tab: any): void {
        if (tab === '1. Basic information') {
            this.list = this.basicInfoList;
        } else if (tab === '2. Incident details') {
            this.list = this.accidentDetailsList;
        } else if (tab === '3. Services') {
            this.list = this.servicesList;
        } else {
            this.list = this.claimsSummaryList;
        }
    }

    changeSelected(tab: any): void {
        if (tab === '1. Basic information') {
            this.list = this.basicInfoList;
        } else if (tab === '2. Incident details') {
            this.list = this.accidentDetailsList;
        } else if (tab === '3. Services') {
            this.list = this.servicesList;
        } else if (tab === '4. Claim summary') {
            this.list = this.claimsSummaryList;
        }
        this.setTabsCurrent(tab);
    }

    setTabsCurrent(selected: any): void {
        let future = false;
        this.tabFlow = 'previous';
        for (let tab = 0; tab < this.tabsList.length; tab++) {
            if (this.currentTab !== this.tabsList[tab].title) {
                this.setSubTab(this.tabsList[tab].title);
            } else if (this.currentTab === this.tabsList[tab].title) {
                this.tabFlow = 'current';
                this.setSubTab(this.tabsList[tab].title);
            }
            if (selected !== this.tabsList[tab].title && !future) {
                this.tabsList[tab].unselectedPast = true;
                this.tabsList[tab].Current = false;
                this.tabsList[tab].unselectedFuture = false;
                this.tabsList[tab].completed = true;
            } else if (selected === this.tabsList[tab].title) {
                this.tabsList[tab].Current = true;
                this.tabsList[tab].unselectedPast = false;
                this.tabsList[tab].unselectedFuture = false;
                future = true;
            } else {
                this.tabsList[tab].Current = false;
                this.tabsList[tab].unselectedPast = false;
                this.tabsList[tab].unselectedFuture = true;
                this.tabsList[tab].completed = false;
            }
        }
    }

    setSubTab(tab: any): void {
        if (this.tabFlow === 'previous') {
            if (tab === '1. Basic information') {
                for (let subtab = 0; subtab < this.basicInfoList.length; subtab++) {
                    this.basicInfoList[subtab].past = true;
                    this.basicInfoList[subtab].current = false;
                    this.basicInfoList[subtab].future = false;
                }
            } else if (tab === '2. Incident details') {
                for (let subtab = 0; subtab < this.accidentDetailsList.length; subtab++) {
                    this.accidentDetailsList[subtab].past = true;
                    this.accidentDetailsList[subtab].current = false;
                    this.accidentDetailsList[subtab].future = false;
                }
            } else if (tab === '3. Services') {
                for (let subtab = 0; subtab < this.servicesList.length; subtab++) {
                    this.servicesList[subtab].past = true;
                    this.servicesList[subtab].current = false;
                    this.servicesList[subtab].future = false;
                }
            }
        } else if (this.tabFlow === 'current') {
            if (tab === '1. Basic information') {
                for (const subtab in this.basicInfoList) {
                    if (this.basicInfoList[subtab].title === this.currentTabSection) {
                        this.basicInfoList[subtab].past = false;
                        this.basicInfoList[subtab].current = true;
                        this.basicInfoList[subtab].future = false;
                        this.tabFlow = 'future';
                    } else if (this.tabFlow === 'future') {
                            this.basicInfoList[subtab].past = false;
                            this.basicInfoList[subtab].current = false;
                            this.basicInfoList[subtab].future = true;
                        } else {
                            this.basicInfoList[subtab].past = true;
                            this.basicInfoList[subtab].current = false;
                            this.basicInfoList[subtab].future = false;
                        }
                }
            } else if (tab === '2. Incident details') {
                for (let subtab = 0; subtab < this.accidentDetailsList.length; subtab++) {
                    if (this.accidentDetailsList[subtab].title === this.currentTabSection) {
                        this.accidentDetailsList[subtab].past = false;
                        this.accidentDetailsList[subtab].current = true;
                        this.accidentDetailsList[subtab].future = false;
                        this.tabFlow = 'future';
                    } else if (this.tabFlow === 'future') {
                            this.accidentDetailsList[subtab].past = false;
                            this.accidentDetailsList[subtab].current = false;
                            this.accidentDetailsList[subtab].future = true;
                        } else {
                            this.accidentDetailsList[subtab].past = true;
                            this.accidentDetailsList[subtab].current = false;
                            this.accidentDetailsList[subtab].future = false;
                        }
                }
            } else if (tab === '3. Services') {
                for (let subtab = 0; subtab < this.servicesList.length; subtab++) {
                    if (this.servicesList[subtab].title === this.currentTabSection) {
                        this.servicesList[subtab].past = false;
                        this.servicesList[subtab].current = true;
                        this.servicesList[subtab].future = false;
                        this.tabFlow = 'future';
                    } else if (this.tabFlow === 'future') {
                            this.servicesList[subtab].past = false;
                            this.servicesList[subtab].current = false;
                            this.servicesList[subtab].future = true;
                        } else {
                            this.servicesList[subtab].past = true;
                            this.servicesList[subtab].current = false;
                            this.servicesList[subtab].future = false;
                        }
                }
            } else if (tab === '4. Claim summary') {
                for (let subtab = 0; subtab < this.claimsSummaryList.length; subtab++) {
                    if (this.claimsSummaryList[subtab].title === this.currentTabSection) {
                        this.claimsSummaryList[subtab].past = false;
                        this.claimsSummaryList[subtab].current = true;
                        this.claimsSummaryList[subtab].future = false;
                        this.tabFlow = 'future';
                    } else if (this.tabFlow === 'future') {
                            this.claimsSummaryList[subtab].past = false;
                            this.claimsSummaryList[subtab].current = false;
                            this.claimsSummaryList[subtab].future = true;
                        } else {
                            this.claimsSummaryList[subtab].past = true;
                            this.claimsSummaryList[subtab].current = false;
                            this.claimsSummaryList[subtab].future = false;
                        }
                }
            }
        } else if (this.tabFlow === 'future') {
            if (tab === '2. Incident details') {
                for (let subtab = 0; subtab < this.accidentDetailsList.length; subtab++) {
                    this.accidentDetailsList[subtab].past = false;
                    this.accidentDetailsList[subtab].current = false;
                    this.accidentDetailsList[subtab].future = true;
                }
            } else if (tab === '3. Services') {
                for (let subtab = 0; subtab < this.servicesList.length; subtab++) {
                    this.servicesList[subtab].past = false;
                    this.servicesList[subtab].current = false;
                    this.servicesList[subtab].future = true;
                }
            } else {
                for (let subtab = 0; subtab < this.claimsSummaryList.length; subtab++) {
                    this.claimsSummaryList[subtab].past = false;
                    this.claimsSummaryList[subtab].current = false;
                    this.claimsSummaryList[subtab].future = true;
                }
            }
        }
    }
}

import { LocationStrategy, PlatformLocation } from '@angular/common';
import { Component, ElementRef, HostListener, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AuthenticatedHeader, FooterData, ImageAttributes } from '@nationwide/internet-servicing-angular-components';
import { APICommonService } from '@nationwide/internet-servicing-angular-services';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { HeaderFooterContent } from './common/headerFooter';
import { PersonalConstants } from './common/personalConstants';
import { ClaimsOidcAuthService } from './efnol-modernization/claims-oidc-auth/claims-oidc-auth.service';
import { LoggerService } from './shared/services/logger.service';
import { SessionService } from './shared/services/session.service';
import { TokenDataService } from './shared/services/token-data.service';
import { UtilService } from './shared/services/util.service';

@Component({
    selector: 'is-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
    appWindow = window;
    isPreEfnol = false;
    claimList = false;
    isEfnol = false;
    tracker = false;
    checklist = false;
    isAuthenticated: boolean;
    isRegisteredAuthenticated: boolean;
    headerData: AuthenticatedHeader;
    navBarValidation: boolean;
    subscription: Subscription;
    urlSubscription: Subscription;
    footerData: FooterData;
    showFooter = true;
    showHeader = true;
    browserRefresh = false;
    urlObj: any = {
        previousUrl: null,
        currentUrl: null
    };
    uniqueKey: string;
    customHeader = ['coverage-details', 'claims-landing'];
    understandCoverages = false;
    userInfo: any;

    constructor (private router: Router,
        private logger: LoggerService,
        private utils: UtilService,
        private apiCommon: APICommonService,
        private tokenDataService: TokenDataService,
        private route: ActivatedRoute,
        private titleService: Title,
        private location: LocationStrategy,
        private ngZone: NgZone,
        private locationp: PlatformLocation,
        private authService: ClaimsOidcAuthService,
        private sessionService: SessionService
    ) {
        if (window.location.href.indexOf('authorizationForm') > -1) {
            this.showHeader = false;
            this.showFooter = false;
        }
        this.location.onPopState(() => {
            if (sessionStorage.getItem('confirmationResponse')) {
                this.sessionService.setButtonClicked(true);
            }
            this.sessionService.blockBrowserBack(true);
            this.ngZone.run(() => this.router.navigateByUrl(this.locationp.pathname.replace('#/', ''))).then();
            return false;
        });
        this.subscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.browserRefresh = !router.navigated;
                this.sessionService.setPageRefresh(this.browserRefresh);
            }
        });

        this.urlSubscription = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            const { url } = event;
            this.urlObj.previousUrl = this.urlObj.currentUrl;
            this.urlObj.currentUrl = url;
            this.sessionService.setUrlObj(this.urlObj);

            const conditions = {
                isEfnol: ['fileclaim', 'modernization', 'find-repair-shop'],
                tracker: ['/dashboard', 'claimspayment'],
                checklist: ['info/accident-checklist'],
                understandCoverages: ['info/coverage-details'],
                isPreEfnol: ['coverage-details', 'claims-landing'],
                claimList: ['claim-list-page']
            };

            for (const condition in conditions) {
                this[condition] = conditions[condition].some((el) => url.includes(el));
            }
            this.sessionService.setSessionItem('isEfnol', this.isEfnol);
        });
    }

    ngOnInit(): any {
        this.utils.checkforIamBypass(this.location.path());
        this.tokenDataService.setDataFromCookieIfPresent();
        this.subscription = this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
        // path location strategy
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (!!event.url && (event.url.match(/^\/#/) || event.url.includes('#'))) {
                    if (event.url.indexOf('?') > -1) {
                        const url = event.url.split('?');
                        const search = url[1].indexOf('claims-list-page#/redirect') > -1 ? url[1].replace('#/redirect', '') : url[1];
                        const queryParamsFound = JSON.parse(`{"${decodeURI(search)
                            .replace(/"/g, '\\"')
                            .replace(/&/g, '","')
                            .replace(/=/g, '":"')}"}`);
                        if (event.url.match('file-upload')) {
                            this.router.navigate([url[0].replace('claims/index.html#/', '')], { queryParams: queryParamsFound });
                        } else {
                            if (this.utils.isDashboardURL(event.url) && queryParamsFound) {
                                sessionStorage.setItem('queryParams', queryParamsFound);
                            }
                            this.router.navigate([url[0].replace('/#', '')], { queryParams: queryParamsFound });
                        }
                    }
                }
            }
        });
        // this is setting the title and footer phone number for claims
        this.setAuthHeaderData();
        this.setFooterData();
        this.setClaimTitleandFooterPhone();
        if (!window.frameElement) {
            const sessionId = this.apiCommon.generateSessionId();
            sessionStorage.setItem('sessionId', sessionId);
        }
        let configId;
        if (this.sessionService.getUserType().indexOf('unregistered') > -1) {
            configId = this.sessionService.getSessionItemDecrypted('fromMFA') === 'true' ? PersonalConstants.LOGINTYPE.UNREGISTEREDEXPANDUSER : PersonalConstants.LOGINTYPE.UNREGISTERED;
        } else {
            configId = PersonalConstants.LOGINTYPE.REGISTERED;
        }
        this.authService.checkOidcAuthStatus(configId).subscribe((checAuthResult) => {
            this.authService.setAccessToken(checAuthResult.accessToken);
            this.authService.setIdToken(this.authService.parseJwt(checAuthResult.idToken));
            this.authService.setAuthStatus(checAuthResult.isAuthenticated, configId === PersonalConstants.LOGINTYPE.REGISTERED);
        });
        this.getUniqueKey();
    }

    setClaimTitleandFooterPhone(): any {
        this.router.events.pipe(filter((e) => e instanceof NavigationEnd),
            map(() => this.route),
            map((route) => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            }),
            filter((route) => route.outlet === 'primary'),
            mergeMap((route) => route.data))
            .subscribe((event) => {
                if (event['title']) {
                    this.titleService.setTitle(event['title']);
                } else {
                    this.titleService.setTitle('Start a claim');
                }
                this.setAuthHeaderData();
                this.setFooterData();
            });
    }

    /**
     * Generates and returns a unique key
     * @returns unique key
     */
    getUniqueKey(): any {
        this.uniqueKey = sessionStorage.getItem('uniqueKey');
        if (!this.uniqueKey) {
            this.uniqueKey = `NWUK${new Date().getTime()}`;
            sessionStorage.setItem('uniqueKey', this.uniqueKey);
            this.logger.setComponentName('AppComponent');
            this.logger.getStartLoadTimer();
            this.logger.info('App started');
        }
        return this.uniqueKey;
    }
    // Called when user leaves this application, but stays within webpage.
    // Will be used when there are multiple applications.
    ngOnDestroy(): any {
        this.logger.debug('User leaving application');
        this.logger.logCurrentContents();
        this.subscription.unsubscribe();
        this.urlSubscription.unsubscribe();
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadHandler(event): any {
        this.logger.debug('User leaving website');
        this.logger.logCurrentContents();
    }

    navBarClicked(navBarValidation: boolean): any {
        this.navBarValidation = navBarValidation;
    }

    setAuthHeaderData(): any {
        this.authService.getAuthStatus().subscribe((authStatus) => {
            this.authService.getUserInfo().subscribe((userdata) => {
                this.userInfo = userdata;
            });
            this.isRegisteredAuthenticated = authStatus.isRegistered && authStatus.isAuthorized;
            this.isAuthenticated = authStatus.isAuthorized;
            if (this.isRegisteredAuthenticated) {
                window.sessionStorage.setItem('authenticatedFlag', JSON.stringify(this.isRegisteredAuthenticated));
                this.headerData = {
                    nationwideLogo: {
                        url: environment.NATIONWIDE.REGISTERED_LOGO_URL,
                        title: HeaderFooterContent.NATIONWIDEHEADERLOGO.TITLE,
                        source: HeaderFooterContent.NATIONWIDEHEADERLOGO.SOURCE,
                        altText: HeaderFooterContent.NATIONWIDEHEADERLOGO.ALTTEXT
                    },
                    username: this.userInfo.firstName,
                    lastLogin: '',
                    contactUs: environment.AUTH_HEADER_LINKS.contactUs,
                    logout: environment.AUTH_HEADER_LINKS.logout,
                    allAccountsUrl: environment.NATIONWIDE.accountSummaryPage,
                    personalInformation: environment.AUTH_HEADER_LINKS.personalInformation,
                    preferences: (!!this.userInfo.firstName && sessionStorage.getItem('placesVisted')) ? environment.AUTH_HEADER_LINKS.preferences : '',
                    manageAuthorizedUsers: environment.AUTH_HEADER_LINKS.manageAuthorizedUsers,
                    navigationLinks: [{ 'text': 'Contact us', 'url': environment.AUTH_HEADER_LINKS.contactUs }]
                };
            }
        });
    }

    setFooterData(): any {
        this.footerData = {
            nationwideLogo: {
                url: environment.NATIONWIDE.HOME,
                title: HeaderFooterContent.NATIONWIDE.TITLE,
                source: HeaderFooterContent.NATIONWIDE.SOURCE,
                altText: HeaderFooterContent.NATIONWIDE.ALTTEXT
            },
            equalHousing: this.displaylogo(!this.isEfnol, 'EQUALHOUSING'),
            truste: this.displaylogo(!this.isEfnol, 'TRUSTE'),
            disclosureContent: this.isEfnol ? HeaderFooterContent.EFNOL_DISCLOSURECONTENT : HeaderFooterContent.DISCLOSURECONTENT,
            privacySecurityContent: this.isEfnol ? '' : `<a href=${environment.FOOTER_LINKS.privacySecurity} target="_blank">Privacy and security</a>`,
            termsConditionsContent: this.isEfnol ? '' : `<a href=${environment.FOOTER_LINKS.termsConditions} target="_blank">Terms and conditions</a>`,
            accessibilityContent: this.isEfnol ? '' : `<a href=${environment.FOOTER_LINKS.accessibility} target="_blank">Accessibility</a>`,
            siteMapContent: this.isEfnol ? '' : `<a href=${environment.FOOTER_LINKS.sitemap} target="_blank">Site Map</a>`,
            isAuthenticated: this.isEfnol ? false : this.isRegisteredAuthenticated,
            phoneNumberText: this.isEfnol ? '' : HeaderFooterContent.CLAIMNUMBERPHONENUMBERTEXT,
            includeChicklets: !this.isEfnol,
            aboutUsLink: this.isEfnol ? '' : environment.NATIONWIDE.aboutUsLink,
            careersLink: this.isEfnol ? '' : environment.NATIONWIDE.careers,
            forBusinessLink: this.isEfnol ? '' : environment.NATIONWIDE.FOR_BUSINESS_URL,
            forIndividualLink: this.isEfnol ? '' : environment.NATIONWIDE.PERSONAL,
            logoutLink: this.isEfnol ? '' : environment.AUTH_HEADER_LINKS.logout,
            onYourSideDefaultNumber: '',
            contactUsLink: this.displayFooterContact(),
            showPhoneNumber: true,
            doNotSellMyPersonalInformationContent: HeaderFooterContent.DONOTSELLMYPERSONALINFORMATION
        };

        this.setSocialMediaLinks();

        if (this.isRegisteredAuthenticated) {
            this.footerData.nationwideLogo.url = environment.NATIONWIDE.REGISTERED_LOGO_URL;
            this.footerData.forIndividualLink = '';
        }
    }

    isProd(): any {
        return environment.production === 'true';
    }

    linkClicked(linkMap): void {
        if (linkMap?.url === environment.AUTH_HEADER_LINKS.logout) {
            this.authService.logOffRegisteredUser();
            sessionStorage.clear();
        }
    }

    setSocialMediaLinks(): void {
        this.footerData.facebook = {
            'url': HeaderFooterContent.FACEBOOK.URL,
            'source': HeaderFooterContent.FACEBOOK.SOURCE,
            'altText': HeaderFooterContent.FACEBOOK.ALTTEXT
        };
        this.footerData.instagram = {
            'url': HeaderFooterContent.INSTAGRAM.URL,
            'source': HeaderFooterContent.INSTAGRAM.SOURCE,
            'altText': HeaderFooterContent.INSTAGRAM.ALTTEXT
        };
        this.footerData.linkedIn = {
            'url': HeaderFooterContent.LINKEDIN.URL,
            'source': HeaderFooterContent.LINKEDIN.SOURCE,
            'altText': HeaderFooterContent.LINKEDIN.ALTTEXT
        };
        this.footerData.youtube = {
            'url': HeaderFooterContent.YOUTUBE.URL,
            'source': HeaderFooterContent.YOUTUBE.SOURCE,
            'altText': HeaderFooterContent.YOUTUBE.ALTTEXT
        };
        this.footerData.twitter = {
            'url': HeaderFooterContent.TWITTER.URL,
            'source': HeaderFooterContent.TWITTER.SOURCE,
            'altText': HeaderFooterContent.TWITTER.ALTTEXT
        };
    }

    displaylogo(display: boolean, logoName: string): ImageAttributes {
        const logo = display ? HeaderFooterContent[logoName] : {
            URL: '',
            SOURCE: '',
            ALTTEXT: ''
        };
        return {
            url: logo.URL,
            title: '',
            source: logo.SOURCE,
            altText: logo.ALTTEXT,
            height: '45px'
        };
    }

    displayFooterContact(): string {
        if (this.isEfnol || this.isPreEfnol) {
            return '';
        } else if (this.isRegisteredAuthenticated) {
            return environment.NATIONWIDE.AUTHORIZED_CONTACTUS_LINK;
        } else if (this.isAuthenticated) {
            return environment.NATIONWIDE.UNAUTHORIZED_CONTACTUS_LINK;
        }
    }

    @ViewChild('mainContent', {static: false} ) mainContent: ElementRef;
    public skipToMainContent(): void {
      this.mainContent.nativeElement.focus();
    }
}


import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { InternetServicingAngularComponentsModule } from '@nationwide/internet-servicing-angular-components';
import { APICommonService } from '@nationwide/internet-servicing-angular-services';
import { StubComponent } from '../stub/stub.component';
import { CancelButtonPopupComponent } from './components/cancel-button-popup/cancel-button-popup.component';
import { ClaimsLiveChatComponent } from './components/claims-live-chat/claims-live-chat.component';
import { IframeComponent } from './components/iframe/iframe.component';
import { NwBannerComponent } from './components/nw-banner/nw-banner.component';
import { NwTestToolModule } from './components/nw-test-tool/nw-test-tool.module';
import { NwTextFormatModule } from './components/nw-textformat/nw-textformat.module';
import { OauthIframeWrapperComponent } from './components/oauth-iframe-wrapper/oauth-iframe-wrapper.component';
import { PreferredContactPipe } from './pipes/preferred-contact.pipe';
import { ClaimsOidcHelperService } from './services/claims-oidc-helper.service';
import { IamBypass } from './services/iam-bypass.service';
import { IframeQueue } from './services/iframe-queue.service';
import { OauthClient } from './services/oauth-client.service';
import { SaveExitService } from './services/save-exit.service';
import { SharedServicesModule } from './services/shared-services.module';
@NgModule({
    imports: [
        CommonModule,
        InternetServicingAngularComponentsModule,
        SharedServicesModule,
        NwTextFormatModule,
        NwTestToolModule,
        MatExpansionModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        PreferredContactPipe,
        OauthIframeWrapperComponent,
        IframeComponent,
        NwBannerComponent,
        ClaimsLiveChatComponent,
        CancelButtonPopupComponent
    ],
    providers: [
        APICommonService,
        ClaimsOidcHelperService,
        SaveExitService,
        StubComponent,
        IframeQueue,
        OauthClient,
        IamBypass,
        { provide: Window, useValue: window }
    ],
    exports: [
        PreferredContactPipe,
        OauthIframeWrapperComponent,
        NwBannerComponent,
        ClaimsLiveChatComponent,
        CancelButtonPopupComponent
    ]
})
export class SharedModule { }

// FOR DOCUMENTATION REFERENCE
// This shared module should use only in pages.
